import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, search, fertilizerNameList) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        var dealerLicence = search.licence_number
        dealerLicence += '\n'
        dealerLicence += dateFormat(search.licence_issue_date)
        const profile = []
        if (search.photo) {
          profile.push({ image: 'data:image/png;base64,' + search.photo, alignment: 'right', style: 'td', width: 120 })
        }
        pdfContent.push({
          table: {
            widths: ['60%', '40%'],
            body: [
                [
                  {
                    table: {
                      widths: ['45%', '10%', '45%'],
                      body: [
                        [
                          { text: vm.$t('fertilizerReport.dealerNid'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: vm.$n(search.nid), alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.dealerLicence'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: dealerLicence, alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.trade'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: (i18n.locale === 'bn') ? search.trade_name_bn : search.trade_name, alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.ownerName'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: (i18n.locale === 'bn') ? search.name_bn : search.name, alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.dealerIDNo'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: (i18n.locale === 'bn') ? search.registration_no_bn : search.registration_no, alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.addres'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: (i18n.locale === 'bn') ? search.pre_address_bn : search.pre_address, alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.disTrict'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: (i18n.locale === 'bn') ? search.distict_name_bn : search.distict_name, alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.upz'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: (i18n.locale === 'bn') ? search.upzila_name_bn : search.upzila_name, alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.assignArea'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: (i18n.locale === 'bn') ? search.region_name_bn : search.region_name, alignment: 'left', style: 'td' }
                        ],
                        [
                          { text: vm.$t('fertilizerReport.dealerPhone'), alignment: 'left', style: 'td' },
                          { text: ':', alignment: 'center', style: 'td' },
                          { text: (i18n.locale === 'bn') ? '০' + vm.$n(search.contact_no, { useGrouping: false }) : '0' + vm.$n(search.contact_no, { useGrouping: false }), alignment: 'left', style: 'td' }
                        ]
                      ]
                    },
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0
                      },
                      vLineWidth: function (i, node) {
                        return 0
                      }
                    }
                  },
                  {
                    table: {
                      headerRows: 1,
                      widths: ['100%'],
                      body: [
                        [
                          profile
                        ]
                      ]
                    },
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0
                      },
                      vLineWidth: function (i, node) {
                        return 0
                      }
                    }
                  }
                ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) {
                return 0
            },
            vLineWidth: function (i, node) {
                return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', rowSpan: 2 },
            { text: vm.$t('fertilizerReport.month_name'), style: 'th', alignment: 'center', rowSpan: 2 },
            { text: vm.$t('fertilizerReport.memo_no_date'), style: 'th', alignment: 'center', rowSpan: 2 },
            { text: vm.$t('fertilizerReport.ferti_alloca'), style: 'th', alignment: 'center', colSpan: 3 },
            {},
            {},
            { text: vm.$t('fertilizerReport.memo_no_date'), style: 'th', alignment: 'center', rowSpan: 2 },
            { text: vm.$t('fertilizerReport.lifted_amount'), style: 'th', alignment: 'center', colSpan: 3 },
            {},
            {},
            { text: vm.$t('fertilizerReport.rest_amount'), style: 'th', alignment: 'center', colSpan: 3 },
            {},
            {},
            { text: vm.$t('fertilizerReport.comments'), style: 'th', alignment: 'center', rowSpan: 2 }
          ]
        ]
        const headList = [
          {},
          {},
          {}
        ]
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        headList.push({})
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        headList.push({})
        allRows.push(headList)
        data.forEach((list, index) => {
          list.sale_memos.forEach((sale, index1) => {
            const NewLoop = []
            if (index1 === 0) {
              NewLoop.push({ text: vm.$n(index + 1), style: 'td', rowSpan: list.sale_memos.length, alignment: 'center' })
              NewLoop.push({ text: vm.$i18n.locale === 'en' ? list.month_en : list.month_bn, style: 'td', rowSpan: list.sale_memos.length, alignment: 'center' })
              var memoAdate = vm.$n(list.memo_no, { useGrouping: false })
              memoAdate += '\n'
              memoAdate += dateFormat(list.allocation_date)
              NewLoop.push({ text: memoAdate, style: 'td', rowSpan: list.sale_memos.length, alignment: 'center' })
              list.allocation_info.forEach((data, index11) => {
                NewLoop.push({ text: vm.$n(data.quantity), style: 'td', rowSpan: list.sale_memos.length, alignment: 'right' })
              })
            } else {
              NewLoop.push({})
              NewLoop.push({})
              NewLoop.push({})
              list.allocation_info.forEach((data, index11) => {
                NewLoop.push({})
              })
            }
            var memoAdateS = vm.$n(sale.memo_no, { useGrouping: false })
            if (sale.sell_date) {
              memoAdateS += '\n'
              memoAdateS += dateFormat(sale.sell_date)
            }
            NewLoop.push(
              { text: memoAdateS, style: 'td', alignment: 'center' }
            )
            sale.fertilizer.forEach((fertilizer, index111) => {
              NewLoop.push({ text: vm.$n(fertilizer.quantity), style: 'td', alignment: 'right' })
            })
            sale.fertilizer.forEach((fertilizer, index111) => {
              NewLoop.push({ text: vm.$n(fertilizer.rest_quantity), style: 'td', alignment: 'right' })
            })
            NewLoop.push({})
            allRows.push(NewLoop)
          })
        })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: ['5%', '5%', '10%', '7%', '8%', '5%', '10%', '7%', '8%', '5%', '7%', '8%', '5%', '5%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 0, 0, 10]
          },
          signature: {
            margin: [30, 30, 30, 20]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('dealer-profile')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
