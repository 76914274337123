<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fertilizerReport.dealerPro') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6">
              <ValidationProvider name="Division" vid="division_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_division.division')"
                label-for="division_id"
                >
                <b-form-select
                  plain
                  v-model="search.division_id"
                  :options="divisionList"
                  id="division_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6">
              <ValidationProvider name="District" vid="district_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('warehouse_report.district')"
                label-for="district_id"
                slot-scope="{ valid, errors }"
                >
                <b-form-select
                plain
                v-model="search.district_id"
                :options="DistrictList"
                id="district_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6">
              <ValidationProvider name="Upazila" vid="upazilla_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
                slot-scope="{ valid, errors }"
                >
                <b-form-select
                plain
                v-model="search.upazilla_id"
                :options="upazilaList"
                id="upazilla_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6">
              <ValidationProvider name="Dealer Name" vid="dealer_name">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('fertilizerConfig.dealer_name')"
                label-for="dealer_name"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{$t('fertilizerConfig.dealer_name')}}  <span class="text-danger">*</span>
                </template>
                <b-form-select
                plain
                v-model="search.dealer_name"
                :options="dealerBasicList"
                id="upazilla_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                    <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6">
                <ValidationProvider name="Year/Month" vid="fiscal_year_id" rules= "required|min_value:1">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-for="fiscal_year_id"
                  :label="$t('fertilizerReport.fiscal')"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('fertilizerReport.fiscal')}}  <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                        <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                      </b-col>
                      <b-col>
                        <b-form-select
                            plain
                            v-model="search.month_id"
                            :options="monthList"
                            id="month_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                      </b-col>
                  </b-row>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6">
                  <ValidationProvider name="Dealer Code" vid="dealer_code" rules= "required">
                      <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="dealer_code"
                       slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('fertilizerReport.dealer_code')}}  <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                          id="dealer_code"
                          v-model="search.dealer_code"
                          placeholder="*Dealer Code/Dealer Name is required"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                  </ValidationProvider>
              </b-col>
            </b-row> <b-row>
                <b-col xs="12" sm="12" md="12" lg="12">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.submit')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('fertilizerReport.dealerPro') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button class="btn_add_new" @click="pdfExport">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row v-show="!loading">
                  <b-col>
                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                      {{ $t('fertilizerReport.dealerPro')}}
                    </list-report-head>
                  </b-col>
                </b-row>
                <slot v-if="kpiData.length > 0">
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-table-simple b hover small caption-top responsive style="width:80%">
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.dealerNid')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>{{$n(searchField.nid)}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.dealerLicence')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>
                            {{searchField.licence_number}}<br/>
                            {{searchField.licence_issue_date|dateFormat}}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.trade')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>{{ $i18n.locale === 'bn' ? searchField.trade_name_bn : searchField.trade_name }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.ownerName')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>{{ $i18n.locale === 'bn' ? searchField.name_bn : searchField.name }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.dealerIDNo')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>{{ $i18n.locale === 'bn' ? searchField.registration_no_bn : searchField.registration_no }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.addres')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>
                            {{ $i18n.locale === 'bn' ? searchField.pre_address_bn : searchField.pre_address }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.disTrict')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>{{ $i18n.locale === 'bn' ? searchField.distict_name_bn : searchField.distict_name }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.upz')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>{{ $i18n.locale === 'bn' ? searchField.upzila_name_bn : searchField.upzila_name }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.assignArea')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>{{ $i18n.locale === 'bn' ? searchField.region_name_bn : searchField.region_name }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td style="width:40%;text-left!important">{{ $t('fertilizerReport.dealerPhone')}}</b-td>
                          <b-td style="width:5%;text-align:center">:</b-td>
                          <b-td>
                             {{ $i18n.locale === 'bn' ? '০' : '0' }} {{$n(searchField.contact_no, { useGrouping: false })}}
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-col>
                    <b-col lg="6" sm="12" style="text-align:right">
                      <br/>
                      <br/>
                      <br/>
                      <img v-if="searchField.photo" :src="'data:image/png;base64,'+ searchField.photo" class="img-fluid" style="width: 150px;"/>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12" class="table-responsive">
                      <b-table-simple class="tg" style="font-size: 11px" hover bordered small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-th rowspan="2" style="text-align:center;vertical-align:middle">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th rowspan='2' style="text-align:center;vertical-align:middle">{{ $t('fertilizerReport.month_name') }}</b-th>
                            <b-th rowspan='2' style="text-align:center;vertical-align:middle">{{ $t('fertilizerReport.memo_no_date') }}</b-th>
                            <b-th colspan="3" style="text-align:center;vertical-align:middle">{{ $t('fertilizerReport.ferti_alloca') }}</b-th>
                            <b-th rowspan='2' style="text-align:center;vertical-align:middle">{{ $t('fertilizerReport.memo_no_date') }}</b-th>
                            <b-th colspan="3" style="text-align:center;vertical-align:middle">{{ $t('fertilizerReport.lifted_amount') }}</b-th>
                            <b-th colspan="3" style="text-align:center;vertical-align:middle">{{ $t('fertilizerReport.rest_amount') }}</b-th>
                            <b-th rowspan='2' scope="colgroup" style="text-align: center;vertical-align:middle"> {{ $t('fertilizerReport.comments') }}</b-th>
                          </b-tr>
                          <b-tr>
                            <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'first_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                            <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'sc_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                            <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'tr_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <template v-for="(list, index) in kpiData">
                            <b-tr v-for="(sale, index1) in list.sale_memos" :key="'data' + index*index1">
                              <slot v-if="index1 === 0">
                                <b-td style="text-align:center;vertical-align:middle" :rowspan="list.sale_memos.length">{{$n(index+1)}}</b-td>
                                <b-td style="text-align:center;vertical-align:middle" :rowspan="list.sale_memos.length">{{ $i18n.locale === 'bn' ? list.month_bn : list.month_en }}</b-td>
                                <b-td style="text-align:center;vertical-align:middle" :rowspan="list.sale_memos.length">
                                  {{ $n(list.memo_no, { useGrouping: false }) }}
                                  <br/>
                                  {{list.allocation_date|dateFormat}}
                                </b-td>
                                <td style="text-align:right;vertical-align:middle" :rowspan="list.sale_memos.length" class="text-right" v-for="(data, index11) in list.allocation_info" :key="index11 + '_first'">{{$n(data.quantity)}}</td>
                              </slot>
                              <b-td style="text-align:center;vertical-align:middle">
                                {{ $n(sale.memo_no, { useGrouping: false }) }}
                                  <br/>
                                <slot v-if="sale.sell_date">
                                  {{sale.sell_date | dateFormat}}
                                </slot>
                              </b-td>
                              <td style="text-align:right;vertical-align:middle" v-for="(fertilizer, index111) in sale.fertilizer" :key="index111 + '_second'">{{$n(fertilizer.quantity)}}</td>
                              <td style="text-align:right;vertical-align:middle" v-for="(fertilizer, index11) in sale.fertilizer" :key="index11 + '_third'">{{$n(fertilizer.rest_quantity)}}</td>
                              <b-td></b-td>
                            </b-tr>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </slot>
                <div class="panel-body text-center mt-3" v-else>
                  <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                </div>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, dealerProfileData } from '../../api/routes'
import ExportPdf from './export-pdf_details'
import ListReportHead from '.././ListReportHead.vue'

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      showData: false,
      search: {
        warehouse_name: '',
        warehouse_type_id: 0,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        fiscal_year_id: 0,
        month_id: 0,
        dealer_code: '',
        dealer_name: 0,
        from_date: '',
        to_date: '',
        warehouse_id: 0,
        org_id: 3
      },
      region_name: '',
      region_name_bn: '',
      distict_name: '',
      distict_name_bn: '',
      upzila_name: '',
      upzila_name_bn: '',
      warehouseName: '',
      warehouseName_bn: '',
      currentDate: '',
      organization: [],
      testId: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      searchField: [],
      kpiData: [],
      warehouseList: [],
      DistrictList: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl
    }
  },
  computed: {
    dealerBasicList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    formTitle () {
       return this.$t('warehouse_service_performance.KPI_Report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.tot_complain'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.tot_resolve'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.ratio'), class: 'text-left' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'warehouse_name_bn' },
          { key: 'tot_complain' },
          { key: 'tot_resolve' },
          { key: 'ratio' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'warehouse_name' },
          { key: 'tot_complain' },
          { key: 'tot_resolve' },
          { key: 'ratio' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    monthList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    authUser () {
      return this.$store.state.Auth.authUser
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal) {
      this.DistrictList = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal) {
      this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal) {
      this.warehouseList = this.getWarehouseName()
      this.unionList = this.getUnionList(newVal)
    },
    'search.union_id': function () {
      this.warehouseList = this.getWarehouseName()
    }
  },
  created () {
  },
  mounted () {
    core.index()
  },
  methods: {
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.showData = true
      // this.organizationData()
      this.loadData()
    },
    getDistrictList (divisionId = null) {
      const regionDistrictLists = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return regionDistrictLists.filter(district => district.division_id === divisionId)
      }
      return regionDistrictLists
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getRegionDistrictList (regionId = null) {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
          if (response) {
              const dataObject = response.filter(document => document.region_id === regionId && document.status === 0)
              this.regionDistrictLists = dataObject.map(obj => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                  } else {
                      return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                  }
              })
          }
        })
      },
    organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === this.search.org_id)
      RestApi.getData(warehouseServiceBaseUrl, reportHeadingList + '/' + this.search.org_id, { org_id: this.search.org_id }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn,
            project_name: orgObject.project_name,
            project_name_bn: orgObject.project_name_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Organization not found!'
          })
        }
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, dealerProfileData, params).then(response => {
        if (response.success) {
          if (response.data) {
            this.kpiData = this.getRelationalData(response.data)
            this.searchField = response.dealer
            const districtList = this.$store.state.commonObj.districtList.find(item => item.value === this.searchField.pre_division_id)
            const upazilaList = this.$store.state.commonObj.upazilaList.find(item => item.value === this.searchField.pre_district_id)
            const regionList = this.$store.state.commonObj.officeList.find(item => item.value === this.searchField.pre_region_id)
            this.searchField.distict_name = typeof districtList !== 'undefined' ? districtList.text_en : ''
            this.searchField.distict_name_bn = typeof districtList !== 'undefined' ? districtList.text_bn : ''
            this.searchField.upzila_name = typeof upazilaList !== 'undefined' ? upazilaList.text_en : ''
            this.searchField.upzila_name_bn = typeof upazilaList !== 'undefined' ? upazilaList.text_bn : ''
            this.searchField.region_name = typeof regionList !== 'undefined' ? regionList.text_en : ''
            this.searchField.region_name_bn = typeof regionList !== 'undefined' ? regionList.text_bn : ''
          }
        } else {
          this.kpiData = []
          this.searchField = []
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const allDatas = []
      data.forEach((element, key) => {
        const monthList = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(element.month))
        element.month_en = typeof monthList !== 'undefined' ? monthList.text_en : ''
        element.month_bn = typeof monthList !== 'undefined' ? monthList.text_bn : ''
        allDatas[key] = element
      })
      return allDatas
    },
    getWarehouseName () {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (this.search.union_id > 0) {
        return warehouseList.filter(warehouse => warehouse.union_id === this.search.union_id)
      }
      if (this.search.upazilla_id > 0) {
        return warehouseList.filter(warehouse => warehouse.upazilla_id === this.search.upazilla_id)
      }
      return warehouseList
    },
    getWarehouseNameSearch (ID) {
      const warehouseData = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (ID) {
        const wareData = warehouseData.filter(item => item.value === ID)
        this.search.warehouse_id = wareData[0].value
        this.warehouseList = wareData
      }
    },
    pdfExport () {
      const reportTitle = this.$t('fertilizerReport.dealerPro')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.kpiData, this, this.searchField, this.fertilizerNameList)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
